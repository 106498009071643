import app from './firebaseCore';

class FunctionsApi {
    constructor(firebase) {
        
        const functions = firebase.functions();
        this._nextTurn = functions.httpsCallable('nextTurn');
    }

    nextTurn = (param) => this._nextTurn(param);
}

export default new FunctionsApi(app);