import React from 'react';
import FirebaseInit from './FirebaseInit';


const Init = () =>
    <React.Fragment>
        <FirebaseInit />
    </React.Fragment>

export default Init;

