import React, { Component } from 'react';
import { Grid, Button, Header } from 'semantic-ui-react';

import numeral from 'numeral'


// function pad(num, size) {
//     var s = num + "";
//     while (s.length < size) s = "0" + s;
//     return s;
// }
class Timebutton extends Component {
    constructor(props) {
        super(props);
        this.state = { countDown: props.time * 10, paused: true, complete: false }

    }

    componentDidMount() {
        //this.run();
    }

    componentWillUnmount() {
        this._stopTimer();
    }

    reset = () => {
        this._stopTimer();
        this.setState(() => ({ countDown: this.props.time * 10, paused: true, complete: false }));

        //this.run();
    }

    run = () => {
        if (this.state.paused) {
            this.setState(() => ({ paused: false }))
            this._runTimer();
        }
    }
    handleButton = () => {
        if (this.state.paused) {
            this.run();
        } else {
            this.setState({ paused: true })
            this._stopTimer();
        }
    }

    _tick = () => {
        if (!this.state.paused) {
            this.setState((state, props) => {
                var nextVal = state.countDown - 1;
                var complete = nextVal <= 0;
                return { countDown: nextVal, complete };
            }, () => {
                if (this.state.complete) {
                    this._stopTimer();
                    this.props.onComplete();
                }
            });
        }
    }

    _runTimer() {
        if (!this.handle) {
            this.handle = setInterval(this._tick, 100);
        }
    }

    _stopTimer() {
        if (this.handle) {
            clearInterval(this.handle);
            this.handle = null;
        }
    }

    render() {
        const { countDown, paused, complete } = this.state;
        const totalSec = countDown / 10;
        const mins = Math.floor(totalSec / 60);
        const minutes = numeral(mins).format('00'); //pad(Math.floor(totalSec / 60), 2);
        const seconds = numeral(totalSec - mins * 60).format('00.0'); //pad(totalSec - minutes * 60, 2);
        const {icon,onClict,onComplete,...props} = this.props
        return (


            <Grid>
                <Grid.Row columns={2} verticalAlign="middle">
                    <Grid.Column textAlign="center">
                        {!complete
                            ? <Button {...props}  icon={(paused) ? "play" : "pause"} onClick={this.handleButton}></Button>
                            : <div>--</div>
                        }
                    </Grid.Column>
                    <Grid.Column textAlign="center"><Header inverted size="medium"> {minutes}:{seconds}</Header></Grid.Column>
                </Grid.Row>

            </Grid>

        );
    }
}




export default Timebutton;