import React, { Component } from 'react'

import { Button, Form, Message, Icon } from 'semantic-ui-react'
import clonedeep from 'lodash/cloneDeep';


import dot from 'dot-object';


const selectOptions = [
    {
        value: 'F',
        label: 'Woman',

    },
    {
        value: 'M',
        label: 'Man'
    }
];


class PlayerEdit extends Component {
    constructor(props) {
        super(props);
        this.state = { player: props.input };
    }

    static defaultProps = {
        input: {
            name: "",
            gender: 'F',
            peers: {
                F: false,
                M: false
            }

        },
    }

    handleInputChange = (event, data) => {

        const value = (data.type === 'checkbox') ? data.checked : data.value;
        const name = data.name;
        const newState = dot.str(name, value, { ...this.state });
        this.setState(newState);
        this.setState({ error: false, sucess: false });
    }

    componentDidMount() {
        this.setState(({ player: { name, ...other } }) => ({ sucess: false, player: { name: '', ...other } }));
    }

    handleSave = async (event, close) => {
        try {
            await this.props.onSubmit(clonedeep(this.state.player), !close);
            if (!close) {
                this.setState(({ player: { name, ...other } }) => ({ sucess: `${name} added`, player: { name: '', ...other } }));
            }
        } catch (error) {
            this.setState({ error: error.message });
        }
    }


    render() {
        const { player, error, sucess } = this.state;

        const isValid = (!!player.name) && (player.peers.M || player.peers.F);

        return (


            <Form>
                <Form.Input label="Name" name="player.name" value={player.name} onChange={this.handleInputChange} error={error} />

                <RadioButtonGroup name="player.gender" value={player.gender} onChange={this.handleInputChange} options={selectOptions} />
                <Form.Checkbox label="Plays with man" name="player.peers.M" checked={player.peers.M} onChange={this.handleInputChange} />
                <Form.Checkbox label="Plays with woman" name="player.peers.F" checked={player.peers.F} onChange={this.handleInputChange} />

                <div>

                    <Button disabled={!isValid} onClick={(e) => this.handleSave(e, true)} >
                        Save
                    </Button>
                    <Button disabled={!isValid} onClick={(e) => this.handleSave(e)} >
                        Save & Add another
                    </Button>

                    {sucess && <Icon name="check" size='large' color='green' />}
                </div>

            </Form>

        );
    }
}

const RadioButtonGroup = (props) => {

    const { options, value, onChange, name } = props;

    const handleChange = (e, val) => {
        e.target.name = name;
        onChange(e, val)
    }

    return (

        <Form.Group inline>
            {options.map(item =>
                <Form.Radio
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    name={name}
                    checked={value === item.value}
                    onChange={handleChange}
                />
            )}
        </Form.Group>

    )

}



export default PlayerEdit;