import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container } from 'semantic-ui-react'

const RespContainer = ({ children, minWidth }) => {
    const isDesktop = useMediaQuery({ minWidth: minWidth || 992 })

    return (
        isDesktop ? <Container>{children}</Container> : children
    );
}

export default RespContainer;