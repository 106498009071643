import _app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
    apiKey: "AIzaSyB2VBVLvYBz7-p7qvqr_TkNakJloU-Kk4g",
    authDomain: "mypartymixer.com",//"gamex-f920c.firebaseapp.com",//
    databaseURL: "https://gamex-f920c.firebaseio.com",
    projectId: "gamex-f920c",
    storageBucket: "gamex-f920c.appspot.com",
    messagingSenderId: "713600964586",
    appId: "1:713600964586:web:ea98293efcdcbd2b7f0493"
};





const app = _app;
app.initializeApp(config);

export default app;

