import React, { useState } from 'react';
import { Button, Segment, Grid, Icon, Header, Modal } from 'semantic-ui-react';
import { Subscribe } from 'unstated';
import { AuthContainer } from '../Providers';
import EmailAuth from './EmailAuth';


const Startscreen = (props) => {

    //const [emailAuth, getEmailAuth] = useState(false);

    const FormatButton = ({ color, children, onClick, icon }) => (
        <Grid.Row>
            <Grid.Column>
                <Button fluid size="large" color={color || icon} onClick={onClick}> <Icon name={icon} /> {children} </Button>
            </Grid.Column>
        </Grid.Row>
    );
    return (
        <Subscribe to={[AuthContainer]}>{(authContainer) =>

            <Grid textAlign='center' style={{ height: '100vh' }} >
                <Grid.Column style={{ maxWidth: 450 }}>
                    <React.Fragment>

                        <Segment raised padded textAlign="center" style={{ marginTop: "40px" }}>
                            <Header as='h2' color='violet' textAlign='center'>
                                Signin</Header>
                            <Segment vertical stacked>
                                <Grid>
                                    <Modal basic centered={false} size="mini"
                                        trigger={<FormatButton color='violet' icon="mail">Email</FormatButton>}>
                                        <Modal.Content>
                                            <EmailAuth />
                                        </Modal.Content>
                                    </Modal>
                                    <FormatButton icon='google plus' onClick={authContainer.doSignInWithGoogle}>Google</FormatButton>
                                    <FormatButton icon='facebook' onClick={authContainer.doSignInWithFacebook}>Facebook</FormatButton>
                                    {/* <FormatButton icon='twitter' onClick={authContainer.doSignInWithTwitter} >Twitter</FormatButton> */}

                                    {/* <Button color='vk'><Icon name='vk' /> VK</Button>
                                    <Button color='linkedin'><Icon name='linkedin' /> LinkedIn</Button>
                                    <Button color='instagram'><Icon name='instagram' /> Instagram</Button>
                                    <Button color='youtube'><Icon name='youtube' /> YouTube</Button> */}


                                </Grid>
                            </Segment>
                        </Segment>

                    </React.Fragment>
                </Grid.Column>
            </Grid>

        }
        </Subscribe>
    )
};



export default Startscreen;