import React from 'react';
import { Form, Segment, Header, Button } from 'semantic-ui-react';

import { withAuthContainer } from '../../Providers';

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password: '', confirm: '', confirmError: undefined, passwordError: undefined };
    }

    handleInputChange = (event, data) => {

        const value = data.type === 'checkbox' ? data.checked : data.value;
        const name = data.name;

        this.setState({
            [name]: value
        }
            //, () => this.validate(name, value)
        );
    }

    // validate(name, value) {
    //     switch (name) {
    //         case 'confirm':
    //             if (value !== this.state.password) {
    //                 this.setState({ confirmError: "Passwords do not match" })
    //             } else {
    //                 this.setState({ confirmError: undefined })
    //             }

    //             break;

    //         default:
    //             break;
    //     }
    // }



    handleSubmitButton = async (event) => {
        const { email, password, confirm } = this.state;
        if (password !== confirm) {
            this.setState({ confirmError: "Passwords do not match" })
            return;
        } else {
            this.setState({ confirmError: undefined })
        }

        if (password.length < 6) {
            this.setState({ passwordError: "Pasword length should be at least 6 characters" });
            return;
        }

        try {
            const cred = await this.props.authContainer.doCreateUserWithEmailAndPassword(email, password);
            console.log(cred);
            this.setState({ email: '', password: '', confirm: '', error: undefined });

        } catch (error) {
            console.log(error);
            this.setState({ error: error.message });
        }
    }

    render() {
        const { email, password, confirm, confirmError } = this.state;
        return (
            <React.Fragment>
                <Header as='h2' color='teal' textAlign='center'>
                    Sign Up with email
                    </Header>
                <Form size='large'>
                    <Segment stacked>
                        <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address'
                            name='email' value={email} onChange={this.handleInputChange} />
                        <Form.Input fluid icon='lock' iconPosition='left' placeholder='Password' type='password'
                            name='password' value={password} onChange={this.handleInputChange} />
                        <Form.Input fluid icon='lock' iconPosition='left' placeholder='Confirm password' type='password'
                            name='confirm' value={confirm} onChange={this.handleInputChange} error={confirmError} />

                        <Button color='teal' fluid size='large' onClick={this.handleSubmitButton}>Register</Button>

                    </Segment>
                </Form>

            </React.Fragment>
        );
    }
}

export default withAuthContainer(SignUp);