
import app from './firebaseCore';

class DataApi  {
    constructor(firebase) {
        
        this.firestore = firebase.firestore();
    }

    user = (uid) => this.firestore.doc(`users/${uid}`);

    tasks = () => this.firestore.collection('tasks');


    players = (user) => this.firestore.doc(`players/${user}`)

    settings = () => this.firestore.doc('global/settings');

}


export default new DataApi(app);