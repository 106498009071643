import React, { useState } from 'react';
import { Button, Tab } from 'semantic-ui-react';
import IfAdmin from './IfAdmin';
import { TaskList } from './Tasks';
import {LevelsList} from './Levels';
import AdminSettings from './AdminSettings';
import RespContainer from '../Semantic/RespContainer';

const tabs = [
    { menuItem: 'Tasks', render: () => <Tab.Pane basic={true} style={{ paddingLeft: "4px", paddingRight: "4px", }} > <TaskList /></Tab.Pane> },
    { menuItem: 'Levels', render: () => <Tab.Pane basic={true} style={{ paddingLeft: "4px", paddingRight: "4px", }} > <LevelsList /></Tab.Pane> },
    
    
    
    { menuItem: 'Settings', render: () => <Tab.Pane> <AdminSettings /></Tab.Pane> }
]

const AdminContent = () =>
    <Tab menu={{ pointing: true }} panes={tabs} />


const AdminSection = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <IfAdmin>
            <RespContainer>
                <AdminContent />
            </RespContainer>
        </IfAdmin>);

}


export default AdminSection;

