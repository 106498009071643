import React from 'react'
import { Accordion, Button, Table, Confirm, Checkbox, Divider, Header, Select, Label, Form, Segment } from 'semantic-ui-react';
import MediaQuery from 'react-responsive'
import { Subscribe } from 'unstated'
import TaskEdit from './TaskEdit';
import { ButtonLink, ModalDialog, LanguageSelect } from '../../Semantic';
import { TasksContainer, Initializer, LevelsContainer } from '../../../Providers';


class TaskList extends React.Component {
    constructor(props) {
        super(props);
        const language = localStorage.getItem('language') || "ru"
        this.state = {
            openEdit: false, confirmDelete: false, edit: null,
            grouped: true,
            language: language
        };
        this.detachlist = [];
    }


    handleEditButton = (item) => {
        this.setState({ openEdit: true, edit: item });
    }

    handleAddButton = (template) => {
        const data = { ...template, ...{ players: ['*', '*'], text: "", time: 40 } };
        this.setState({ openEdit: true, edit: { id: "", data } });
    }

    handleDeleteButton = (item) => {
        this.setState({ confirmDelete: true })
        this.itemToDelete = item;
    }


    handleClose = () => {
        this.setState({ openEdit: false })
    }

    handleSetLanguage = (language) => {
        // if (language === "") {
        //     language = undefined;
        // }
        this.setState({ language })
        localStorage.setItem("language", language);
    }

    applyFilter = (items) => {
        const { language } = this.state;
        return items.filter(itm => language ? itm.data.language === language : true);
    }

    render() {

        const { openEdit, confirmDelete, edit, grouped, language } = this.state;

        const handleConfirmDelete = (tasksContainer) => {
            tasksContainer.deleteTask(this.itemToDelete);
            this.setState({ confirmDelete: false })
        }

        return (
            <Subscribe to={[TasksContainer, LevelsContainer]}>{(tasksContainer, levelsContainer) =>
                <React.Fragment>
                    <Form unstackable>
                        <Form.Group inline>
                            <Form.Field width={6}>
                                <label>Grouped</label>
                                <Checkbox toggle checked={grouped} onChange={() => this.setState({ grouped: !grouped })} />
                            </Form.Field>
                            <Form.Field width={6}>
                                <LanguageSelect clearable placeholder="Language" value={language} onChange={(e, d) => { this.handleSetLanguage(d.value) }} />
                            </Form.Field>
                            {/* <Form.Field fluid control={Select} width={8} name="data.level" label="Language" value={1} onChange={() => { }} /> */}
                        </Form.Group>
                    </Form>
                    <Divider />
                    {grouped
                        ? this.renderGrouped(tasksContainer, levelsContainer)
                        : this.renderFlat(tasksContainer)
                    }

                    <ModalDialog title="Task" input={edit} open={openEdit} onClose={this.handleClose} onSave={tasksContainer.saveTask}>
                        <TaskEdit levels={levelsContainer.levels} />
                    </ModalDialog>

                    <Confirm centered={false}
                        confirmButton="Yes"
                        open={confirmDelete}
                        onCancel={() => this.setState({ confirmDelete: false })}
                        onConfirm={() => handleConfirmDelete(tasksContainer)} />
                </React.Fragment>
            }</Subscribe>
        );
    }

    renderFlat(tasksContainer) {

        const items = this.applyFilter(tasksContainer.tasks);
        return (
            <React.Fragment>

                <Button primary size="tiny" onClick={() => this.handleAddButton({})} >Add</Button>
                {this.renderTable(tasksContainer, items)}
            </React.Fragment>);
    }

    renderGrouped(tasksContainer, levelsContainer) {

        const renderLevel = (level) => {
            const items = this.applyFilter(tasksContainer.tasksByLevel[level] || []);

            return (
                <React.Fragment>
                    <Button primary size="tiny" onClick={() => this.handleAddButton({ level })} >Add</Button>
                    {this.renderTable(tasksContainer, items)}
                </React.Fragment>
            );
        }
        const randerLevelPanel = ({ value: level, text }) => {
            const items = this.applyFilter(tasksContainer.tasksByLevel[level] || []);

            const _PanelContent = () =>
                <React.Fragment>
                    <Button primary size="tiny" onClick={() => this.handleAddButton({ level })} >Add</Button>
                    {this.renderTable(tasksContainer, items)}
                </React.Fragment>
            const _TitleContent = () =>
                <Label pointing='left' color='red'>{items.length}<Label.Detail>{text}</Label.Detail></Label>
            {/* <Segment compact>{text}<Label floating>{items.length}</Label></Segment> */ }


            return {
                key: level,
                title: { content: <_TitleContent /> },
                content: {
                    content: <_PanelContent />
                }
            }
        }

        return (
            <Accordion
                exclusive={false}
                panels={levelsContainer.levels.map(randerLevelPanel)} />
        );
    }



    renderTable = (tasksContainer, items) =>
        <Table celled unstackable compact='very' size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Pls</Table.HeaderCell>
                    <Table.HeaderCell>Text</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map((item) =>
                    <Table.Row key={item.id}>
                        <Table.Cell className="narrow" textAlign="center">
                            <Button icon="copy" compact size='mini' basic onClick={() => { tasksContainer.duplicateTask(item) }} />
                            <Divider hidden />
                            <Checkbox checked={item.data.active} onChange={() => tasksContainer.toggleActivateTask(item)} />

                        </Table.Cell>

                        <Table.Cell className="narrow">
                            [{item.data.players.join()}]
                                    <Divider hidden />
                            {item.data.time}
                        </Table.Cell>
                        <Table.Cell>
                            <ButtonLink onClick={() => { this.handleEditButton(item) }}>
                                {item.data.text}
                            </ButtonLink>
                            <Divider hidden />
                            <Label.Group size="small">
                                <Label basic color="red">{item.data.level}</Label>
                                {item.data.language && <Label color="green"> {item.data.language}</Label>}
                            </Label.Group>
                        </Table.Cell>
                        <Table.Cell className="narrow">
                            <Button compact size='mini' basic icon="trash" onClick={() => { this.handleDeleteButton(item) }} />

                        </Table.Cell>


                    </Table.Row>
                )}
            </Table.Body>
        </Table>

}





export default TaskList;