import React from 'react';
//import { Subscribe } from 'unstated';
//import { DataAccessContainer } from '../../Providers';


const FirebaseInit = () => <div></div>
    {/* <Subscribe to={[DataAccessContainer]}>{
        dataAccess =>
            <LevelsInit dataAccess={dataAccess} />
    }</Subscribe> */}




const defaultLevels = [
    { text: 'Platonic (0)', value: 'platonic' },
    { text: 'Soft (1)', value: 'soft' },
    { text: 'Hard (2)', value: 'hard' },
    { text: 'Hard Plus (3)', value: 'hardplus' },
    { text: 'Dirty (4)', value: 'dirty' },
]






const LevelsInit = ({ dataAccess }) => {
    
    return("Nothing");
    // dataAccess.levels().set(defaultLevels).then(res => {
    //     console.log("LevelsInit", res);
    //     return (
    //         <div>
    //             (JSON.stringify(res))
    //     </div>
    //     );
    // });
}


export default FirebaseInit;