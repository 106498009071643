
import React from 'react'
import { Select, TextArea, Form, Input, Button } from 'semantic-ui-react';
import insertTextAtCursor from 'insert-text-at-cursor';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { LanguageSelect } from '../../Semantic';
//import map from 'lodash/map';
//import languageCodes from './languages.json';

const genderOptions = [
    { key: 'm', text: 'M', value: 'M' },
    { key: 'f', text: 'F', value: 'F' },
    { key: 'a', text: '*', value: '*' },
]

// const levelOptions = [
//     { text: 'Platonic (0)', value: 'platonic' },
//     { text: 'Soft (1)', value: 'soft' },
//     { text: 'Hard (2)', value: 'hard' },
//     { text: 'Hard Plus (3)', value: 'hardplus' },
//     { text: 'Dirty (4)', value: 'dirty' },
// ]

//map(languagesMap,(value, key) => ({ key, value: key, text: value.nativeName }));
//const languages = languageCodes.map(({ code, name }) => ({ key: code, value: code, text: name }));


class TaskEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = { ...props.input };
    }

    static defaultProps = {
        input: {
            id: "",
            data: {
                players: ['*', '*'],
                text: "",
                time: 40,
                active: false,
                level: "platonic"
            }
        },
    }

    handleInputChange = (event, data) => {

        const value = (data.type === 'checkbox') ? data.checked : data.value;
        const name = data.name;

        const newState = set(cloneDeep(this.state), name, value);

        this.setState(newState);

    }

    handleSaveTask = () =>
        this.props.onSubmit(this.state);



    handleInsertButton(event, data) {
        console.log(event, data);
        const el = document.getElementById('task-text');
        insertTextAtCursor(el, " {" + data.name + "} ");
    }

    render() {
        const { id, data } = this.state;
        const levels = this.props.levels.map(({ text, value }) => ({ text, value, key: value }));
        const isValidText = data.players.reduce((prev, cur, i) => prev && data.text.includes("{" + i + "}"), true);

        return (

            <Form unstackable size="small">
                {id && <Form.Input label='Id' value={id} readOnly />}
                <Form.Group>
                    <Form.Field fluid control={Select} label='User{0}' name="data.players[0]" options={genderOptions} width={8} value={data.players[0]} onChange={this.handleInputChange} />
                    <Form.Field fluid control={Select} label='User{1}' name="data.players[1]" options={genderOptions} width={8} value={data.players[1]} onChange={this.handleInputChange} />
                </Form.Group>

                <Form.Field>
                    <span>
                        <label>Task</label>
                        <Button.Group style={{ padding: "5px" }} labeled size="mini" compact>
                            <Button name="0" onClick={this.handleInsertButton}>{'{0}'}</Button>
                            <Button name="1" onClick={this.handleInsertButton}>{'{1}'}</Button>
                        </Button.Group>
                    </span>
                    <TextArea id="task-text" name="data.text" value={data.text} onChange={this.handleInputChange} ></TextArea>
                </Form.Field>
                <Form.Group>
                    <Form.Field fluid control={Input} width={8} label='Time' type='number' name="data.time" value={data.time} onChange={this.handleInputChange} />
                    <Form.Field fluid control={Select} width={8} label='Level' name="data.level" value={data.level} options={levels} onChange={this.handleInputChange} />
                    <Form.Field fluid control={LanguageSelect} search width={8} label='Language' name="data.language" value={data.language} onChange={this.handleInputChange} />
                </Form.Group>
                <p />
                <Form.Button disabled={!isValidText || !data.language } primary onClick={this.handleSaveTask}>Save</Form.Button>
            </Form>

        )
    }
}



export default TaskEdit;