
import groupBy from 'lodash/groupBy';
import { Container, Subscribe } from 'unstated'
import { dataApi } from '../Components/Firebase';


class TasksContainer extends Container {
    constructor() {
        super();
        this.detachlist = [];
        this.state = {
            tasks: [],
            tasksByLevel: []
        };

        this.watch();
    }

    get tasks() { return this.state.tasks; }
    get tasksByLevel() { return this.state.tasksByLevel; }

    updateTimestamp=(task)=>{
        
    }

    saveTask = (item) => {

        const updateTaskBeforeSave = (task) => {
            task.pattern = getPayersPattern(task);
        }

        updateTaskBeforeSave(item.data);
        if (item.id && item.id !== "") {
            return dataApi.tasks().doc(item.id).set(item.data);
        } else {
            return dataApi.tasks().add(item.data);
        }

    }

    duplicateTask = (item) => {
        dataApi.tasks().add({ ...item.data });
    }

    toggleActivateTask = (item) => {
        const active = item.data.active;
        item.data.active = !active;
        this.saveTask(item);

    }

    deleteTask = (item) => {
        if (item) {
            dataApi.tasks().doc(item.id).delete();
        }
    }

    watchTasks = () => {
        return dataApi.tasks().onSnapshot(snapshot => {
            //console.log("tasks-snapshot", snapshot);
            if (!snapshot.empty) {
                const tasks = snapshot.docs.map(t => ({ id: t.id, data: t.data() }));
                const byLevel = groupBy(tasks, 'data.level');
                this.setState({ tasks: tasks || [], tasksByLevel: byLevel });
            }
        })
    }


    watch = () => {
        this.detachlist.push(this.watchTasks());
    }

    unwatch = () => {
        while (this.detachlist.length > 0) {
            this.detachlist.pop()();
        }
    }

}

export default TasksContainer;




function getPayersPattern(task) {

    function expandPlayers2(ps) {//['F', '*'],
        function rec(lst, i) {
            if (i > lst.length - 1) {
                return [""];
            }
            const tail = rec(lst, i + 1);
            if (lst[i] === '*') {
                return [...tail.map(t => 'M' + t), ...tail.map(t => 'F' + t)];

            }
            else {
                return tail.map(t => lst[i] + t);
            }
        }
        return rec(ps, 0);
    }
    return expandPlayers2(task.players);

}

