import React from 'react'
import { Redirect } from 'react-router-dom';
import { Subscribe } from 'unstated';

import { AuthContainer } from '../../Providers';

const IfAdmin = ({ children, rediretTo }) =>
    <React.Fragment>
        <Subscribe to={[AuthContainer]}>{
            ({ state: { isAdmin } }) =>
                isAdmin ? children : <Redirect to={rediretTo || "/"} />
        }</Subscribe>
    </React.Fragment>





export default IfAdmin;