import React from 'react';
import { Input } from 'semantic-ui-react';
const ENTER_KEY_CODE = 13;
export default class EditableInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: this.props.isEditing || false,
            value: this.props.value || "",
            shadowValue: this.props.value || ""
        };

        this.inputRef = React.createRef()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.shadowValue) {

            return { value: nextProps.value, shadowValue: nextProps.value };
        }
        return null;
    }

    handleLabelFocus = () => {
        if (!this.state.isEditing) {
            this.setState({ isEditing: true }, () => {
                this.inputRef.current.focus();
            });

        }
    }

    handleInputBlur = () => {
        if (this.state.isEditing) {
            this.setState({ isEditing: false });
            this.props.onChange && this.props.onChange(this.state.value);
        }
    }

    handleChange = (e, d) => {
        this.setState({ value: d.value });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY_CODE) {
            this.handleInputBlur();
        }
    }

    render() {
        const { isEditing, value } = this.state;


        if (isEditing) {
            return (
                <Input value={value} ref={this.inputRef} onBlur={this.handleInputBlur} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
            );
        }
        else {
            return (
                <span onClick={this.handleLabelFocus}
                >{value}</span>
            );
        }
    }


}