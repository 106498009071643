import React from 'react';
import { Provider } from 'unstated'
import Main from './Components/Main';
// import Firebase, { FirebaseContext } from './Components/Firebase';


// import fireBaseCore from './Components/Firebase/firebaseCore';

// import { AuthContainer } from './Providers';
// const firebase = new Firebase(fireBaseCore);
// const auth = new AuthContainer();

function App(props) {
    return (
                    <Provider>
                <Main />
            </Provider>
       
    );
}

export default (App);
