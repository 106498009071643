
import React, { useState } from 'react';
import { Message, Button, Segment } from 'semantic-ui-react';
import {ButtonLink} from '../Semantic';
import SignIn from './SignIn';
import SignUp from './SignUp';


const EmailAuth = (props) => {

    const [alreadySigned, setAlreadySigned] = useState(true);



    return (
        <React.Fragment>
            {alreadySigned && <Segment>
                <SignIn />
                <Message>
                    New to us?  <ButtonLink onClick={()=>setAlreadySigned(false)} > Sign Up</ButtonLink>. 
                    Forgot password? <ButtonLink onClick={()=>{}} > Reset pasword</ButtonLink> 
                </Message>
            </Segment>
            }
            {!alreadySigned &&
                <Segment>
                    <SignUp />
                    <Message>
                        Already have account? <ButtonLink onClick={()=>setAlreadySigned(true)} > Sign In</ButtonLink> 
                        
                        
                    </Message>
                </Segment>
            }

        </React.Fragment >

    );

}


export default EmailAuth;