import React from 'react';

import { Checkbox, Segment } from 'semantic-ui-react';

const AdminSettings = (props) => {

    

    return (
        <Segment>
            <Checkbox checked={true} label='Disable Timer' onChange={(e, data) => {
                console.log(data);
                
            }} />
        </Segment>
    );
}


export default AdminSettings;