import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Subscribe } from 'unstated';
import { AuthContainer } from '../Providers';
import GameMain from './Game/GameMain';
import Startscreen from './Startsecreen';
import { AdminSection, IfAdmin } from './Admin';
import Init from './Initializers';

class Main extends React.Component {

    render() {
        return (

            <Subscribe to={[AuthContainer]}>{({ state: { authenticated, authUser, isAdmin } }) =>

                <Router>
                    <React.Fragment>
                        {!authenticated && <Startscreen authUser={authUser} />}
                        {authenticated &&
                            <Switch>
                                <Route path="/admin">
                                    <IfAdmin><AdminSection /></IfAdmin>
                                </Route>
                                <Route path="/adminInit">
                                    <IfAdmin><Init /></IfAdmin>
                                </Route>
                                <Route path="/">
                                    <GameMain />
                                </Route>
                            </Switch>
                        }

                    </React.Fragment>
                </Router>

            }</Subscribe>


        );
    }
}

export default Main;


