import React from 'react'

import { Select } from "semantic-ui-react";
import languageCodes from './languages.json';
const languages = languageCodes.map(({ code, name }) => ({ key: code, value: code, text: name }));


const LanguageSelect = ({...props}) =>
    <Select options={languages} search {...props} />


export default LanguageSelect;