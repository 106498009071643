import React from 'react';
import { Form, Segment, Header, Button, Message } from 'semantic-ui-react';

import { withAuthContainer } from '../../Providers';


class SignInEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password: '', confirm: '', error: undefined };
    }

    handleInputChange = (event, data) => {

        const value = data.type === 'checkbox' ? data.checked : data.value;
        const name = data.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmitButton = async (event) => {
        const { email, password, confirm } = this.state;
        try {
            const cred = await this.props.authContainer.doSignInWithEmailAndPassword(email, password);
            console.log(cred);
            this.setState({ email: '', password: '', confirm: '', error: undefined });

        } catch (error) {
            console.log(error);
            this.setState({ error: error.message });
        }
    }

    render() {
        const { email, password, confirm, error } = this.state;
        return (

            <React.Fragment>
                <Header as='h2' color='teal' textAlign='center'>
                    Sign In with email
                    </Header>
                <Form size='large' error>
                    <Segment stacked>
                        <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address'
                            name='email' value={email} onChange={this.handleInputChange} />
                        <Form.Input fluid icon='lock' iconPosition='left' placeholder='Password' type='password'
                            name='password' value={password} onChange={this.handleInputChange} />
                        <Message error content={error} />
                        <Button color='teal' fluid size='large' onClick={this.handleSubmitButton}>Login</Button>

                    </Segment>
                </Form>
            </React.Fragment>

        );
    }
}



export default withAuthContainer(SignInEmail);