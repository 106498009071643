
import React, { Component } from 'react'
import { Button, Header, Table, Grid, Segment, Icon,Loader, Dimmer } from 'semantic-ui-react';
import PlayerEdit from './PlayerEdit';
import { ModalDialog, GlowingButton } from '../Semantic';


function PeersText({ peers }) {

    const { M, F } = peers;
    //? peers.reduce(({ M, F }, x) => x === 'M' ? { M: true, F } : (x === 'F' ? { M, F: true } : { M, F }), { M: false, F: false })
    //: {};
    const text = M ? (F ? "Man and Woman" : "Man") : F ? "Woman" : "";
    return text;

}


function GenderText(props) {
    const { gender } = props;
    const [text, icon, color] = gender === 'F' ? ["Woman", "woman", "red"] : (gender === 'M' ? ['Man', "man", "blue"] : ["", "question"]);
    return (
        <div style={{ "fontSize": "1.2em" }}>
            <Icon size="large" color={color} name={icon} /><span >{text}</span>
        </div>
    );
}

class PlayerList extends Component {
    constructor(props) {
        super(props);
        this.state = { editing: false }
    }

    openAddPlayer = () => {

        this.setState({ editing: true })

    }

    closeAddPlayer = () => {
        this.setState({ editing: false })

    }


    updatePlayers = (players) => {
        const { onPlayersChanged } = this.props;
        return onPlayersChanged(players);
    }


    onDeletePlayer = (player) => {
        const { players } = this.props;

        const idx = players.findIndex(p => p.name === player.name);
        if (idx >= 0) {
            const newPlayers = players.filter((_, i) => i !== idx)
            this.updatePlayers(newPlayers);
        }
    }


    onSavePlayerEdit = (player) => {
        const { players } = this.props;

        if (players.findIndex(p => p.name === player.name) >= 0) {
            throw new Error("player already exist " + player.name);

        }
        else {
            const newPlayers = [...players, player];
            return this.updatePlayers(newPlayers);

        }
    }

    render() {
        const { editing } = this.state;
        const { players, ready } = this.props;
        const DeleteBtn = (props) => <Button inverted basic color='violet' icon="delete" onClick={() => this.onDeletePlayer(props.player)} />

        return (
            <Segment inverted style={{ overflow: 'auto', height: '85vh' }}>
                <Dimmer active={!ready}>
                    <Loader size='medium'>Loading</Loader>
                </Dimmer>
                <Grid>
                    <Grid.Row columns={2} style={{ paddingBottom: "0px" }}>
                        <Grid.Column>
                            <Header inverted as='h2' >Players</Header>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <GlowingButton glowing={players.length < 2} basic icon="add" inverted color="violet" onClick={this.openAddPlayer} content="Add Player" />
                            <ModalDialog title="Add Player" open={editing} onClose={this.closeAddPlayer} onSave={this.onSavePlayerEdit} >
                                <PlayerEdit />
                            </ModalDialog>


                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign="top">
                        <Grid.Column>
                            <Table unstackable inverted>
                                <Table.Header>
                                    <Table.Row >
                                        <Table.HeaderCell>
                                            <Header inverted as='h3' image>
                                                <Header.Content>
                                                    Name
                                                    <Header.Subheader>Gender</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.HeaderCell>
                                        {/* <Table.HeaderCell>Gender</Table.HeaderCell> */}
                                        <Table.HeaderCell>Plays With</Table.HeaderCell>
                                        <Table.HeaderCell width={2}></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body >
                                    {players.map(player =>
                                        <Table.Row key={player.name} >
                                            <Table.Cell>
                                                <Header inverted as='h3' image>
                                                    {/* <Image src='/images/avatar/small/lena.png' rounded size='mini' /> */}
                                                    <Header.Content>
                                                        {player.name}
                                                        <Header.Subheader><GenderText gender={player.gender} /></Header.Subheader>
                                                    </Header.Content>
                                                </Header>
                                            </Table.Cell>
                                            <Table.Cell><PeersText peers={player.peers} /></Table.Cell>
                                            <Table.Cell><DeleteBtn player={player} /></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>

                            </Table>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Segment>
        );


    }
}


export default PlayerList;

