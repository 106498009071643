import React from 'react';
import { Segment, Grid, Header, Transition, Loader, Dimmer } from 'semantic-ui-react';
import ReactStringTemplate, { template as strTemplate } from 'react-string-template';
import { toast } from 'react-semantic-toasts';
import Timebutton from './Timebutton';
import { functionsApi } from "../Firebase";
import sound from '../sound.mp3'

/*
function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}
*/

class Gamescreen extends React.Component {
    constructor(props) {
        super(props);

        this.timerbutton = React.createRef();

        this.state = { time: 5, timeout: true, template: "", names: [], visible: false };

    }

    componentDidMount() {


        this.onNext();
        this.sound = new Audio(sound)
        this.sound.addEventListener('ended', () => {
            this.onNext();
        });
        this.sound.addEventListener('error', () => {
            this.onNext();
        });
        this.sound.addEventListener('stalled', () => {
            this.onNext();
        });
        this.sound.addEventListener('abort', () => {
            this.onNext();
        });
    }

    doNextTurn = async () => {
        this.setState({ visible: false })

        try {
            const { level } = this.props;
            const turn = await functionsApi.nextTurn({ level });

            const { task: { text, time }, players } = turn.data;

            this.setState({ visible: true, template: text, names: players.map(p => p.name), time })
        } catch (error) {
            toast({ type: "error", description: error.message, size: "small" });
        }

    }

    onTimerComplete = () => {
        const noSound = this.state.time <= 0;
        this.setState({ timeout: true });
        noSound ? this.onNext() : this.sound.play();

        //Delay This
        //this.onNext();
    }

    onReset = () => {
        console.log("reset", this.state.time)

        if (this.timerbutton.current) {
            this.timerbutton.current.reset();
        }
    }
    onNext = () => {
        console.log("on Next");
        this.doNextTurn();
        this.setState({ timeout: false });
        if (this.timerbutton.current) {
            this.timerbutton.current.reset();
            //this.timerbutton.current.run();
        }
    }

    skipClickCount = 0;
    onSkipClick = () => {
        this.skipClickCount++;
        setTimeout(() => { this.skipClickCount = 0; }, 3000)
        if (this.skipClickCount > 2) {
            this.skipClickCount = 0;
            this.onNext();
        }
    }

    render() {
        const { time, timeout, template, names, visible } = this.state;
        const namesFormated = names.map((m, i) => <span style={{ fontSize: "1.2em", color: "violet" }}>{m}</span>);
        return (
            <React.Fragment>

                <Segment inverted>
                    <Dimmer active={!visible}>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                    <Grid style={{ height: '76vh' }}>
                        <Grid.Row verticalAlign="middle" onClick={this.onSkipClick}>
                            <Grid.Column textAlign="center" >

                                <Transition visible={visible} animation="fade down" duration="500">
                                    <Header inverted size="huge">
                                        {strTemplate(template, namesFormated)}
                                        {/* <ReactStringTemplate str={template} values={namesFormated} /> */}
                                    </Header>
                                </Transition>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlign="bottom">
                            <Grid.Column textAlign="center" style={{ height: "68px" }}>
                                {visible &&
                                    <React.Fragment>
                                        {(timeout)
                                            ? <Grid>
                                                <Grid.Row columns={1} verticalAlign="middle">
                                                    <Grid.Column textAlign="center">
                                                        {/* <Button basic color='violet' fluid onClick={this.onNext}>Next</Button> */}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>

                                            : <Timebutton inverted fluid color='violet' ref={this.timerbutton} time={time} onComplete={this.onTimerComplete}></Timebutton>
                                        }
                                    </React.Fragment>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </React.Fragment>
        );
    }
}


export default Gamescreen;