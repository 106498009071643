import React from 'react'

import { Button, Table, Icon, Checkbox, Divider, Header, Input } from 'semantic-ui-react';
import JSONPretty from 'react-json-pretty';
import arrayMove from 'array-move';
import { LevelsContainer, withContainer } from '../../../Providers';
import { EditableInput } from '../../Semantic';



class LevelsList extends React.Component {
    constructor(props) {
        super(props);
        const { levelsContainer } = this.props;
        this.state = { levels: levelsContainer.levels, reload: true }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.reload){
            if(JSON.stringify(nextProps.levelsContainer.levels) !== JSON.stringify(prevState.levels)) {
                return { levels: nextProps.levelsContainer.levels, reload: false };
            }
            else{
                return null;
            }
        }
        return { reload: false };
    }

    setLevel(i, level) {
        const levels = [...this.state.levels];
        levels[i] = { ...levels[i], ...level };
        this.setState({ levels });
    }

    moveLevel(i, move) {
        const to = i + move;
        if (to < 0 || to >= this.state.levels.length)
            return false;
        const levels = arrayMove(this.state.levels, i, to)
        this.setState({ levels })
    }

    reloadLevels = () => {
        this.setState({ reload: true })
    }

    newLevel = () => {
        const { levels } = this.state;
        const levelKey = "level";
        let idx = 1;

        while (!!levels.find(l => l.value === levelKey + " " + idx)) {
            idx++;
        }
        let levelName = levelKey + " " + idx;
        const setter = { levels: [...levels, { value: levelName, text: "name" }] };
        console.log(setter);
        this.setState(setter);
    }

    render() {
        const { levels } = this.state;
        const { levelsContainer } = this.props;
        return (

            <React.Fragment>
                <Table celled unstackable compact='very' size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}></Table.HeaderCell>
                            <Table.HeaderCell width={2}>Order</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Value</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {levels.map(({ value: level, text }, i) =>
                            <Table.Row key={level}>
                                <Table.Cell>
                                    <Button icon="caret up" size='tiny' compact basic onClick={() => this.moveLevel(i, -1)} />
                                    <Button icon="caret down" size='tiny' compact basic onClick={() => this.moveLevel(i, 1)} />
                                </Table.Cell>
                                <Table.Cell>{i}</Table.Cell>
                                <Table.Cell><EditableInput value={level} onChange={(value) => { this.setLevel(i, { value }) }} /></Table.Cell>
                                <Table.Cell><EditableInput value={text} onChange={(text) => { this.setLevel(i, { text }) }} /></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <Button floated='right' primary size='small' onClick={this.newLevel}>Add Level</Button>
                                <Button size='small' onClick={() => levelsContainer.saveLevels(levels)}>Save</Button>
                                <Button size='small' onClick={this.reloadLevels}>Undo</Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>
                <JSONPretty id="json-pretty" data={levels}></JSONPretty>

            </React.Fragment>

        )
    }

}
const withLevelsContaner = withContainer(LevelsContainer, "levelsContainer");

export default withLevelsContaner(LevelsList);