import React from 'react';
import { Container, Menu, Dropdown, Icon } from 'semantic-ui-react'
import { SemanticToastContainer } from 'react-semantic-toasts';
import { Subscribe } from 'unstated'
import { PlayersContainer, Initializer, AuthContainer, LevelsContainer } from '../../Providers'

import RespContainer from '../Semantic/RespContainer';
import Gamescreen from './Gamescreen';
import PlayerList from './PlayerList';


class GameMain extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            appstate: 'playerlist',
        };
    }

    startGame = (level) =>
        this.setState({ appstate: 'gamescreen', level });

    handleOnChangeStartGame = (evt, data) => {
        this.startGame(data.value)
    }

    handleBack = () => {
        this.setState(({ appstate }) => {
            switch (appstate) {
                case 'gamescreen': return { appstate: 'playerlist' }
                default:
                    break;
            }
        })
    }

    render() {
        const { appstate, level } = this.state;
        return (
            <Subscribe to={[AuthContainer, PlayersContainer, LevelsContainer]}>{(auth, playersContainer, levelsContainer) =>
                <Initializer init={() => playersContainer.watch(auth.authUser().email)} destroy={playersContainer.unwatch} >
                    <RespContainer>

                        <Menu icon='labeled' inverted>
                            <Menu.Item disabled={appstate === 'playerlist'} link onClick={this.handleBack}>
                                <Icon name='arrow left' size='large' />
                                Back
                            </Menu.Item>
                            {appstate === 'playerlist' &&
                                <Dropdown icon={null} disabled={playersContainer.players.length <= 1}
                                    onChange={this.handleOnChangeStartGame}
                                    trigger={<Menu.Item><Icon name="play" size='large' />Play</Menu.Item>}
                                    options={levelsContainer.levels.map(({ text, value }) => ({ text, value, key: value }))}
                                />
                            }

                            <Menu.Menu position='right'>
                                <Dropdown icon={null} trigger={<Menu.Item><Icon name="user outline" />{auth.authUser().email}</Menu.Item>}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => auth.doSignOut()}>
                                            Signout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Menu.Menu>
                        </Menu>

                        <SemanticToastContainer />


                        <div>
                            {appstate === "playerlist" &&
                                <PlayerList players={playersContainer.players} ready={playersContainer.ready} onPlayersChanged={playersContainer.savePlayers} />}
                            {appstate === "gamescreen" &&
                                <Gamescreen level={level} />}
                        </div>

                    </RespContainer>
                </Initializer>

            }</Subscribe>
        );
    }
}



export default GameMain;