import React from 'react';

class Initializer extends React.Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
        const { targets = [] , init} = this.props;
        targets.forEach(t => t.onInit && t.onInit())
        init && init();
    }
    componentWillUnmount() {
        const { targets = [] ,destroy} = this.props;
        targets.forEach(t => t.onInit && t.onDestroy())
        destroy && destroy();
    }
    render = () => this.props.children;
}

export default Initializer;