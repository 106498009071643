import AuthContainer from './AuthContainer';
import PlayersContainer from './PlayersContainer'
import Initializer from './Initializer';
import TasksContainer from './TasksContainer';
import LevelsContainer from './LevelsContainer';
import withContainer from './withContainer';

const withAuthContainer = withContainer(AuthContainer,"authContainer");
const withLevelsContaner = withContainer(LevelsContainer,"levelsContainer");

export {
    AuthContainer,
    PlayersContainer,
    TasksContainer,
    LevelsContainer,
    Initializer,
    withContainer,
    withAuthContainer,
    withLevelsContaner
};