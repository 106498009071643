
import { Container, Subscribe } from 'unstated'
import { dataApi } from '../Components/Firebase';


class PlayersContainer extends Container {
    constructor() {
        super();
        this.detachlist = [];
        this.state = {
            players: [],
            ready: false
        };
    }

    get players() { return this.state.players; }
    get ready() { return this.state.ready; }

    savePlayers = async (players) => {

        const res = await dataApi.players(this.currentUser).set({ players: players });

        return res;
    }

    watchPlayers = (currentUser) => {
        this.currentUser = currentUser;
        return dataApi.players(currentUser).onSnapshot(snapshot => {
            console.log("players-snapshot", snapshot);
            if (!snapshot.empty) {
                this.setState({ players: snapshot.data().players || [], ready: true });
            }
        });
    }


    watch = (currentUser) => {
        this.detachlist.push(this.watchPlayers(currentUser));
    }

    unwatch = () => {
        while (this.detachlist.length > 0) {
            this.detachlist.pop()();
        }
    }

}


export default PlayersContainer;

