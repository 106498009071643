import React from 'react'
import { Button } from 'semantic-ui-react'

import './GlowingButton.css'

const GlowingButton = ({ glowing, ...props }) =>
    <Button className={glowing ? 'glowing' : ''} {...props} />




export default GlowingButton;