import { Container, Subscribe } from 'unstated'
import { dataApi } from '../Components/Firebase';


class LevelsContainer extends Container {
    constructor() {
        super();
        this.detachlist = [];
        this.state = {

            levels: [],

        };

        this.watch();

    }

    get levels() { return this.state.levels; }

    saveLevels = (levels) => {
        dataApi.settings().set({ levels })

    }



    watchLevels = () => {
        return dataApi.settings().onSnapshot(snapshot => {
            console.log("levels-snapshot", snapshot);
            if (!snapshot.empty) {
                this.setState({ levels: snapshot.data().levels || [] });
            }
        });
    }

    watch = (currentUser) => {

        this.detachlist.push(this.watchLevels());
    }

    unwatch = () => {
        while (this.detachlist.length > 0) {
            this.detachlist.pop()();
        }
    }
}

export default LevelsContainer;

