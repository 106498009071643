import React from 'react';

import { Modal, Loader, Dimmer } from "semantic-ui-react";



class ModalDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { waiting: false }
    }


    handleOnClose = () => {
        this.props.onClose && this.props.onClose();
    }

    handleOnSubmit = async (values, noClose) => {
        this.setState({ waiting: true });
        try {
            var promise = this.props.onSave && this.props.onSave(values);
            if (promise instanceof Promise) {
                await promise;
            }
            if (!noClose) {
                this.handleOnClose();
            }
            return promise;
        } catch (error) {
            throw error;
        } finally {
            this.setState({ waiting: false });
            
        }
    }

    render() {

        return (
            <React.Fragment>
                <Modal open={this.props.open} closeIcon centered={false} onClose={this.handleOnClose} >
                    <Modal.Header>
                        {this.props.title}
                    </Modal.Header>
                    <Modal.Content>
                        {React.cloneElement(this.props.children, { input: this.props.input, onSubmit: this.handleOnSubmit })}

                    </Modal.Content>
                </Modal>

                <Dimmer active={this.state.waiting} page>
                    <Loader />
                </Dimmer>

            </React.Fragment>
        );
    }
}



export default ModalDialog;