import { Container } from 'unstated'
import { dataApi, firebaseCore as firebase } from '../Components/Firebase';


class AuthContainer extends Container {
    constructor() {
        super();
        this.auth = firebase.auth();
        this.dac = dataApi;

        /* Social Sign In Method Provider */
        this.googleProvider = new firebase.auth.GoogleAuthProvider();
        // this.googleProvider.setCustomParameters({
        //     prompt: 'select_account'
        //   });
        this.facebookProvider = new firebase.auth.FacebookAuthProvider();
        this.twitterProvider = new firebase.auth.TwitterAuthProvider();
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        this.state = {
            authenticated: !!authUser,
            authUser,
            isAdmin: authUser && authUser.roles && authUser.roles.admin
        };

        this.onAuthUserListener(
            authUser => {
                localStorage.setItem('authUser', JSON.stringify(authUser));
                this.setState({
                    authenticated: !!authUser,
                    authUser,
                    isAdmin: authUser && authUser.roles && authUser.roles.admin
                });
            },
            () => {
                localStorage.removeItem('authUser');
                this.setState({ authenticated: false, authUser: null, isAdmin: false });
            },
        );
    }
    authUser = () => this.state.authUser;
    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);

    doSignInWithTwitter = () =>
        this.auth.signInWithPopup(this.twitterProvider);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
        });

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.dac.user(authUser.email)
                    .get()
                    .then(snapshot => {
                        if (!snapshot.exists) {
                            this.dac.user(authUser.email).set({ roles: {} });
                        }
                        const dbUser = snapshot.data() || {};
                        // default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }
                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        };
                        //this.authUser = authUser;
                        next(authUser);
                    });
            } else {
                //this.authUser = null;
                fallback();
            }
        });

}


export default AuthContainer;